export const pt = {

    bottom_siac_desc: "Sistema de Informação de Animais de Companhia.<br/>" +
        "A base de dados de registo obrigatório para cães, gatos e furões.",

    bottom_siac_desc_2: "A reunião dos dados do titular e do animal contribuem<br/>" +
        "para a defesa do abandono animal.",


    contacts: "Contactos",
    email: "E-mail",
    phone: "Contacto telefónico",
    address: "Morada",

    lost_list: "Animais perdidos",
    register_lost: "Perdi um animal",
    register_found: "Encontrei um animal",
    how_works: "Como funciona",

    links: "Ligações",

};

export default pt;
