import React from "react"
import ErrorPage from "./ErrorPage"
import locale from "./locales/locales"
import useTranslations from "system/Translations/UseTranslations";
import ErrorImage from "modules/ErrorPages/assets/images/error.svg";

const InternalError = (props) => {
    const {t} = useTranslations('errors', locale);
    return <ErrorPage {...props} message={t("INTERNAL_ERROR")}>
        <ErrorImage/>
    </ErrorPage>
}

export default InternalError;
