import {useCallback, useState} from "react";
import isFunction, {isPromise} from "system/Utils/isFunction";

const useDebounce = (fn, delay, immediate = false) => {

    const [, setTimer] = useState(null);
    return useCallback(
        (...args) => {
            return new Promise(function (resolve, reject) {

                setTimer(timer => {

                    const runNow = !timer && immediate;

                    if (timer) {
                        clearTimeout(timer);
                    }

                    if (runNow) {
                        executeFunction(fn, resolve, reject, args);
                    }

                    return setTimeout(() => {
                        setTimer(null);

                        if (!immediate) {
                            executeFunction(fn, resolve, reject, args);
                        }

                    }, delay)

                });
            })

        },
        [setTimer, fn, delay, immediate]
    );
}


const executeFunction = (fnc, resolve, reject, args) => {

    const result = fnc.apply(this, args);
    if (result && isPromise(result)) {
        result
            .then(resolve)
            .catch(reject)
    }
    else if (isFunction(resolve)) {
        resolve(result);
    }

}

export default useDebounce;
