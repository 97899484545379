import {config} from '@fortawesome/fontawesome-svg-core';

// Make sure this is before any other `fontawesome` API calls
config.autoAddCss = false

const appConfig = {
    timezone: "Europe/Lisbon",
    language: "pt_PT",
    //baseURL: "https://perdidos.siac.bleen.tech",
    baseURL: "https://perdidos.siac.vet",
    api: {
        //base: "https://staging.siac.vet/api/",
        base: "https://portal.siac.vet/api/",
        //base: "https://siac.bleen.tech/api/",
        queryParamStringifyOptions: {
            strictNullHandling: true,
            skipNulls: true,
            arrayFormat: 'comma',
        }
    }
}


export default appConfig;
