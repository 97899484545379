import React, {createContext, useMemo} from "react"

const RestStateContext = createContext({});

function useRestConfig() {
    const context = React.useContext(RestStateContext)
    if (!context) {
        throw new Error(`useApiState must be used within a RestStateProvider`)
    }
    return context
}


const RestfulProvider = (props) => {

    const {base, requestOptions, onError} = props;

    const value = useMemo(
        () => ({
            base: base,
            requestOptions: requestOptions,
            onError: onError
        }),
        [base, requestOptions, onError]
    );

    return <RestStateContext.Provider value={value}>
        {props.children}
    </RestStateContext.Provider>;

}

export {RestfulProvider, useRestConfig}
