export const en = {
    "title": "Ooops!",
    "404_NOT_FOUND": "Page not found!",
    "GENERIC_ERROR": "Something Went Wrong!",
    "403_UNAUTHORIZED": "You are not authorized!!",

    "baseline": "Now.. You can return to home page!",

    go_homepage: "Go to Homepage",
    go_back: "Go Back",

};

export default en;
