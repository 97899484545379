const isFunction = (functionToCheck) => functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
const isPromise = (p) => typeof p === 'object' && typeof p.then === 'function';

const returnsPromise = (f) =>  {

    if (!f)
        return false;

    if (
        f.constructor.name === 'AsyncFunction' ||
        (typeof f === 'function' && isPromise(f()))
    ) {
        return true;
    }

    return false;
}


export default isFunction;
export {isPromise, returnsPromise}
