import React from "react";
import {CacheProvider} from '@emotion/react';
import createCache from '@emotion/cache';
import MainLayout from "./src/layout/MainLayout";

import "./src/layout/assets/css/main.scss";
import "./src/layout/assets/css/fonts.scss";
import "./src/layout/assets/css/layout/layout.scss";
import "./src/layout/assets/css/react-dropdown.scss";
import "@fortawesome/fontawesome-svg-core/styles.css"

const emotionalNonce = process.env.EMOTIONAL_NONCE;

const styleCache = createCache({
    key: 'siac-css',
    nonce: emotionalNonce,
});


// Wraps every page in a component
export const wrapPageElement = ({element, props}) => {
    return (
        <CacheProvider value={styleCache}>
            <MainLayout {...props}>{element}</MainLayout>
        </CacheProvider>
    );
}
