import * as React from "react"
import BottomBanner from "layout/components/BottomBanner/BottomBanner";
import appConfig from "configs/config";
import {RestfulProvider} from "system/Rest/RestfulProvider";
import {LoadingStateProvider} from "modules/Loading/LoadingStateContext";
import ErrorInterceptor from "modules/ErrorPages/ErrorInterceptor";

export const MainLayout = props => {

    return <RestfulProvider base={appConfig.api.base}>
        <ErrorInterceptor>
            <LoadingStateProvider>
                <div className={"main-layout"}>
                    {props.children}

                    <BottomBanner/>
                </div>
            </LoadingStateProvider>
        </ErrorInterceptor>
    </RestfulProvider>

}

export default MainLayout;
