export const pt = {
    "title": "Ooops!",
    "404_NOT_FOUND": "Página não encontrada!",
    "GENERIC_ERROR": "Alguma coisa não correu como esperado.",
    "403_UNAUTHORIZED": "A sua conta atual não está autorizada a aceder a este recurso.",
    "INTERNAL_ERROR": "Ocorreu um erro na aplicação!",
    "INTERNET_ERROR": "Ocorreu um erro a tentar estabelecer a ligação com o servidor. Por favor verifique se está ligado à internet e volte a tentar novamente",

    "baseline": "Por favor utilize uma das opções abaixo para voltar à plataforma",

    refresh: "Atualizar",
    go_back: "Voltar atrás",
    go_dashboard: "Ir para página Inicial",
};

export default pt;
